import { useEffect } from "react";
import { logError } from "../services/ApiServices/LoggingService";
import * as rewardsApi from "../services/ApiServices/ApiService";

const QuantumMetrics = () => {
  useEffect(() => {
    getQMUrl();
    async function getQMUrl() {
      const handleError = (error) => {
        logError(error, "error when calling rewardsApi.getQMUrl", "getQMUrl in QuantumMetrics.jsx");
      };

      const handleSuccess = (responseData) => { 
        if(responseData != null && responseData !== ""){
          var qtm = document.createElement("script");
          qtm.async = true;
          qtm.src = responseData;   
          document.body.appendChild(qtm);
        } 
      };
      await rewardsApi.getQMUrl(handleSuccess, handleError);
    }
     
  }, []);
  return <></>;
};

export default QuantumMetrics;
