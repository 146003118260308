import React, { useState, useEffect } from "react";
import { UserContext } from "./userContext";
import {
    DOCUSIGN_EVENT,
    DOCUSIGN_SIGNING_COMPLETE,
    DOCUSIGN_DECLINE,
} from "../utils/constants";
import { useNavigate } from "react-router-dom";
import * as rewardsApi from "../services/ApiServices/ApiService";
import { logError } from "../services/ApiServices/LoggingService";

const UserContextProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        var location = window.location.pathname;

        if (location !== "/sessionExpired" && location !== "/accessDenied" && location !== "/error" && location !== "/rewardsOffline") {
            getUserInfo();
        }

        async function redirectToEcams() {
            const handleError = (error) => {
                logError(error, "error when calling rewardsApi.getEcamsUrl", "redirectToEcams in UserContextProvider.jsx");
            };

            const handleSuccess = (responseData) => {
                var ecamsUrl = responseData;
                window.location.href = ecamsUrl;
            };

            await rewardsApi.getEcamsUrl(handleSuccess, handleError);
        }

        async function getUserInfo() {
            const handleError = (error) => {
                logError(error, "error when calling rewardsApi.getUserInfo", "getUserInfo in UserContextProvider.jsx");
                var location = window.location.pathname;

                if (location !== "/login" && location !== "/logout") {
                    redirectToEcams();
                }
            };

            const handleSuccess = (responseData) => {
                var location = window.location.pathname;

                if(responseData.loginRequired) {
                    if (location !== "/login" && location !== "/logout") {
                        redirectToEcams();
                    }
                }
                else if (responseData.accessDenied) {
                    window.location.href = "/rewardsOffline";
                } else if (responseData.sessionExpired) {
                    navigate("/sessionExpired", { state: { redirectUrl: responseData.redirectUrl } });
                } else {
                    setUser(responseData);

                    if (
                        location !== "/taxForm" &&
                        location !== "/bankingDetails" &&
                        location !== "/error" &&
                        location !== "/accessDenied"
                    ) {
                        let query, eventType;

                        if (location === "/") {
                            query = new URLSearchParams(window.location.search);
                            eventType = query.get(DOCUSIGN_EVENT);
                        }

                        const isProducer =
                            responseData.role === "IA Primary Agent" ||
                            responseData.role === "IA Producer";
                        const participationIndicator =
                            responseData.enrollmentStatus.participationIndicator;
                        const participationLevel =
                            responseData.enrollmentStatus.participationLevel;

                        if (participationIndicator === false) {
                            navigate("/accessDenied");
                        } else if (
                            isProducer &&
                            participationIndicator &&
                            participationLevel === "AGY"
                        ) {
                            navigate("/accessDenied");
                        } else if (
                            location === "/" &&
                            responseData.enrollmentStatus.taxInfo &&
                            responseData.enrollmentStatus.bankingOnFile
                        ) {
                            if (eventType && (eventType === DOCUSIGN_SIGNING_COMPLETE || eventType === DOCUSIGN_DECLINE)) {
                                navigate("/home/?event=" + eventType);
                            } else if (eventType && eventType !== (DOCUSIGN_SIGNING_COMPLETE && eventType !== DOCUSIGN_DECLINE)) {
                                navigate("/error");
                            } else {
                                navigate("/home");
                            }
                        } else {
                            if (eventType && (eventType === DOCUSIGN_SIGNING_COMPLETE || eventType === DOCUSIGN_DECLINE)) {
                                navigate("/onboarding/?event=" + eventType);
                            } else if (eventType && (eventType !== DOCUSIGN_SIGNING_COMPLETE && eventType !== DOCUSIGN_DECLINE)) {
                                navigate("/error");
                            } else if (
                                !responseData.enrollmentStatus.taxInfo ||
                                !responseData.enrollmentStatus.bankingOnFile
                            ) {
                                navigate("/onboarding");
                            }
                        }
                    }
                }
            };

            await rewardsApi.getUserInfo(handleSuccess, handleError);
        }
    }, [navigate]); 

    return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};

export default UserContextProvider;
