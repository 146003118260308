import React, { useState, useEffect, createContext } from "react";
import * as rewardsApi from "../services/ApiServices/ApiService";
import { logError } from "../services/ApiServices/LoggingService";
import { useNavigate } from "react-router-dom";

export const FeatureFlagContext = createContext();

const FeatureFlagContextProvider = ({ children }) => {
  const [featureFlags, setFeatureFlags] = useState(null);

  const navigate = useNavigate();

  async function getAllFeatureFlags() {
    const handleError = (error) => {
      logError(error, "error when calling rewardsApi.getAllFeatureFlags", "getAllFeatureFlags in FeatureFlagContextProvider.jsx");
    };

    const handleSuccess = (responseData) => {
      setFeatureFlags(responseData);
    };
    await rewardsApi.getAllFeatureFlags(handleSuccess, handleError);
  }

  useEffect(() => {
    getAllFeatureFlags();
  }, [navigate]);

  return (
    <FeatureFlagContext.Provider value={featureFlags}>
      {children}
    </FeatureFlagContext.Provider>
  );
};

export default FeatureFlagContextProvider;
