import axios from "axios";

const buildInfo = ({ response, message, action, request = "" }) => {
    return {
        Response: JSON.stringify(response),
        Message: JSON.stringify(message),
        Action: JSON.stringify(action),
        Request: JSON.stringify(request)
    };
}

const logError = ({ errorResponse, message, action, request = "" }) => {
    var errorLogInfo = buildInfo({ errorResponse, message, action, request });
    axios.post('/api/Logging/WriteErrorLog', errorLogInfo, { headers: { "Content-Type": "application/json" } });
};  

export { logError };